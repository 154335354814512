#channel-dashboard-content .upload-video-btn {
  background: var(--clr-light-400);
  color: var(--clr-dark-300) !important;
  padding: 7px 24px !important;
}

#channel-dashboard-content .upload-video-container {
  padding: 4rem 4vw;
}

#channel-dashboard-content .upload-video-container > img {
  margin: 10px auto;
  width: 40%;
}

#channel-dashboard-content h2 {
  color: var(--clr-dark-300);
  font-size: var(--fs-500);
  font-weight: 600;
}

#channel-dashboard-content .upload-video-container > p {
  font-size: var(--fs-300);
  color: var(--fs-dark-100);
  margin: 12px 0;
}

#channel-dashboard-content .channel-analytics h4 {
  font-size: var(--fs-400);
  color: var(--clr-dark-300);
  font-weight: 700;
}

#channel-dashboard-content .channel-analytics h6 {
  font-size: var(--fs-300);
  color: var(--clr-dark-100);
}
