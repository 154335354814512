@import './theme/root-variables.css';

/* THIS FILE WILL CONTAIN OVERRIDE BOOTSTRAP CSS */

/* BUTTON */

button:focus {
  outline: none;
}

.btn {
  border-radius: var(--radius-5);
  font-weight: 400;
}

.btn.focus,
.btn:focus,
.btn-secondary.focus,
.btn-secondary:focus {
  box-shadow: none !important;
}

.bg-primary,
.btn-primary,
.btn-outline-primary:hover,
.badge-primary {
  border-color: transparent !important;
  background: var(--clr-primary-400);
  color: #fff !important;
  /* font-weight: 300; */
  /* Old browsers */
  background: -moz-linear-gradient(
    104deg,
    var(--clr-primary-400) 0%,
    var(--clr-primary-500) 100%
  ) !important;
  /* FF3.6-15 */
  background: -webkit-linear-gradient(
    104deg,
    var(--clr-primary-400) 0%,
    var(--clr-primary-500) 100%
  ) !important;
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    104deg,
    var(--clr-primary-400) 0%,
    var(--clr-primary-500) 100%
  ) !important;
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='var(--clr-primary-400)', endColorstr='var(--clr-primary-500)',GradientType=1 ) !important;
  /* IE6-9 fallback on horizontal gradient */
}

.btn-primary:hover {
  opacity: 0.65;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--clr-primary-400);
  border-color: var(--clr-primary-400);
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem var(--clr-primary-300);
}

.btn-outline-primary {
  border-radius: 5px !important;
  border-color: var(--clr-primary-400) !important;
  color: var(--clr-primary-400) !important;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem var(--clr-primary-300) !important;
}

/* author buttons */
.single-video-author .btn-light {
  border: 1px solid var(--clr-border-dropdown) !important;
  background: var(--clr-bg-dropdown) none repeat scroll 0 0;
}
/* .single-video-author .btn-light:focus {
  background-color: #fff !important;
} */

.btn-light {
  background: transparent none repeat scroll 0 0;
  color: var(--clr-dark-300) !important;
  border-color: transparent;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  background-color: var(--clr-hover-light-btn);
  border-color: transparent;
}
.btn-light.disabled,
.btn-light:disabled {
  background-color: var(--clr-F6F6F6-252525);
  border-color: var(--clr-F6F6F6-252525);
}
.btn-light:hover {
  background-color: var(--clr-hover-light-btn);
  border-color: transparent;
}
.btn-light:focus,
.btn-light.focus {
  box-shadow: none !important;
  background-color: transparent !important;
  border-color: transparent;
}

.btn-lg {
  font-size: 15px !important;
  padding: 10px 38px !important;
  font-weight: 600 !important;
}

/* button with icon */

.btn-icon.btn-primary,
.btn-icon.btn-secondary,
.btn-icon.btn-danger,
.btn-icon.btn-dark {
  fill: #fff;
}
.btn-icon.btn-light {
  fill: var(--clr-dark-300);
}
.btn-icon.btn > svg {
  fill: inherit;
}

/* .close {
  color: var(--clr-black2white);
  opacity: 0.8;
} */

/* DROPDOWN */
.dropdown-menu.show {
  width: 100%;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background: var(--clr-F6F6F6-252525);
}

.profile-avatar-dropdown .dropdown-menu {
  width: 250px;
  max-width: 250px;
  max-height: 618px;
  border-radius: var(--radius-5);
  margin-top: 5px !important;
  border: 1px solid var(--clr-border-dropdown);
  background-color: var(--clr-bg-dropdown);
  backdrop-filter: blur(28px);
  -webkit-backdrop-filter: blur(28px);
  box-shadow: none !important;
}

.dropdown-menu {
  border: 1px solid var(--clr-border-dropdown);
  background-color: var(--clr-bg-dropdown);
  border-radius: var(--radius-5);
  box-shadow: 0px 3px 6px var(--dropdown-shadow);
}

.profile-avatar-dropdown .dropdown-item:hover,
.dropdown-item:focus {
  background-color: var(--clr-border-dropdown) !important;
}
.dropdown-item.a.btn-light:active,
.dropdown-item:active {
  background-color: var(--clr-border-dropdown) !important;
  color: #212529 !important;
  text-decoration: none;
}

.dropdown-toggle::after {
  display: none;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--clr-primary-500);
  color: #fff !important;
}

.dropdown-header {
  color: #333 !important;
  padding: 7px 13px !important;
}

.dropdown-menu .dropdown-item {
  padding: 6px 14px !important;
  display: flex;
  align-items: center;
  color: var(--clr-dark-300);
}

.dropdown-menu .dropdown-item svg {
  fill: var(--clr-dropdown-icon);
  width: 18px;
  height: 18px;
}

.dropdown-menu .dropdown-item .left h6 {
  color: var(--clr-dark-300);
}

.dropdown-menu .dropdown-item .right {
  margin-left: auto;
}

.dropdown-menu .dropdown-item .right img {
  width: 36px;
}

.dropdown-menu .dropdown-item > h6 {
  font-size: var(--fs-300);
  margin-left: 15px;
  color: var(--clr-dark-300);
}

.dropdown-divider {
  border-top: 1px solid #eff2f3;
}

/* .dropdown-item:hover,
.dropdown-item:focus {
  background-color: transparent !important;
} */

/* BORDER RADIUS OR ROUNDED */
.rounded-sm {
  border-radius: var(--radius-5) !important;
}

/* Modal */
/* .modal {
  display: flex !important;
  justify-content: center;
  align-items: center;
} */
.modal.show .modal-dialog {
  top: 50%;
  transform: translateY(-50%);
}

.modal-content {
  background-color: var(--clr-light-400) !important;
  border-radius: 26px !important;
}

.modal-title {
  font-size: var(--fs-500);
  color: var(--clr-dark-300);
}

.modal-header,
.modal-footer {
  border: none;
}

.modal-header .close:focus {
  outline: none;
}

/* ----- FORM ------ */
.form-control {
  color: var(--clr-dark-100) !important;
  background: var(--clr-light-400) none repeat scroll 0 0 !important;
  /* border-color: #dcdfdf !important; */
  /* border-color: #343434 !important; */
  border-color: var(--clr-border-dropdown) !important;
  border-radius: var(--radius-5) !important;
  font-size: 13px !important;
}

/* label */
.form-label {
  /* font-size: var(--fs-200);
  font-weight: 600;
  margin: 0 0 5px; */
  color: var(--t-clr-dark-400);
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
  line-height: 24px;
}

.input-group-text {
  background-color: var(--clr-home-search-btn);
  border: 1px solid var(--clr-home-search-btn-border);
}

/* Select */
.custom-select {
  color: var(--clr-dark-100) !important;
  background-color: var(--clr-light-400) !important;
  /* border-color: #dcdfdf !important; */
  border-color: var(--clr-border-dropdown) !important;
  border-radius: var(--radius-5) !important;
  font-size: 13px !important;
}

/* Check Box */
.custom-checkbox .custom-control-label::before {
  border-radius: var(--radius-5);
}
.category-checkbox .custom-control {
  margin: 0 0 6px !important;
}
input[type='checkbox'],
input[type='checkbox']:hover {
  cursor: pointer;
}
input[type='radio'],
input[type='radio']:hover {
  cursor: pointer;
}

.form-check-input {
  cursor: pointer;
}

/* Switch */
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--clr-primary-400);
  background-color: var(--clr-primary-400);
}

/* Form Input error or feedback */
.invalid-feedback {
  margin-top: 4px;
  min-height: 20px;
  /* font-family: 'Roboto', 'Noto', sans-serif; */
  font-weight: 400;
  color: var(--clr-primary-400);
  -webkit-font-smoothing: antialiased;
  font-size: 13px;
  line-height: 20px;
}
.form-control:focus,
.custom-select:focus {
  box-shadow: none !important;
}

/* search input 2 */
.input-search2 {
  background: transparent !important  ;
  border: none;
  width: 64px;
  padding-right: 0 !important;
  transition: all 0.2s ease;
  font-size: var(--fs-300) !important;
}

.input-search2::placeholder {
  font-size: var(--fs-400);
}

.input-search2:focus,
.input-search2:active {
  /* background: transparent !important  ;
  box-shadow: none; */
  /* border-bottom: 1px solid black;
  border-radius: 0 !important; */
  width: 200px;
}

/* Table */
.nav-tabs {
  border: none;
}

.table th,
.table td {
  border-top: 1px solid var(--clr-table-border);
}

.table tr td {
  font-size: var(--fs-300);
  font-weight: 400;
}

.table th {
  font-size: var(--fs-300);
  font-weight: 600;
  vertical-align: middle !important;
}

.table thead th {
  border-bottom: none;
}
.table tr > td {
  max-width: 360px;
}

#video-analytic-tabs {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: block;
  cursor: default;
}

#video-analytic-tabs .nav-tabs .nav-link,
.custom-tabs > .tab-item {
  flex: 1;
}

.nav-tabs .nav-link,
.custom-tabs > .tab-item {
  font-size: var(--fs-400);
  font-weight: 500;
  color: var(--clr-dark-300);
  padding: 0.5rem;
  white-space: nowrap;
  text-align: center;
}
#video-analytic-tabs .nav-tabs .nav-link.active,
#video-analytic-tabs .nav-tabs .nav-item.show .nav-link {
  border: none;
  border-top: 2px solid var(--clr-primary-400);
  /* border-radius: 5px; */
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link,
.custom-tabs > .tab-item.active {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid var(--clr-primary-400);
  font-weight: 600;
  font-size: var(--fs-400);
  color: var(--clr-primary-400);
}

.nav-tabs .nav-link:focus {
  border-color: transparent transparent var(--clr-primary-400) transparent;
}
.nav-tabs .nav-link:hover {
  color: var(--clr-primary-400);
  /* border-color: transparent; */
}

.navbar-light .navbar-brand {
  color: var(--t-clr-dark-400);
}
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: var(--t-clr-dark-400);
}
/* shaka video player */
.shaka-video {
  aspect-ratio: 16/9;
}
.shaka-video-container {
  width: 100%;
}

[data-shaka-player-container] .shaka-vertical-volume-container {
  position: relative;
}

[data-shaka-player-container]
  .shaka-vertical-volume-container:hover
  .shaka-volume-bar-container {
  opacity: 1;
}

[data-shaka-player-container]
  .shaka-vertical-volume-container
  .shaka-mute-button {
  color: #fff;
  height: 32px;
  margin: 1px 6px;
  padding: 0;
  background: 0 0;
  border: 0;
  cursor: pointer;
}

[data-shaka-player-container]
  .shaka-vertical-volume-container
  .shaka-volume-bar-container {
  position: absolute;
  order: initial;
  width: 100px;
  top: -58px;
  left: -35px;
  transition: all 300ms ease-in-out 100ms;
  transform: rotate(-90deg);
  opacity: 0;
}

[data-shaka-player-container]
  .shaka-vertical-volume-container
  .shaka-volume-bar-container
  input {
  cursor: pointer;
}

#watermark {
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease-out;
}
#watermark:hover {
  opacity: 1 !important;
}
.shaka-video-container .shaka-controls-container[shown='true'] ~ #watermark {
  transform: translateY(-45px);
}
.shaka-controls-container {
  z-index: auto !important;
}

/* tag css */
.react-tagsinput {
  background-color: var(--clr-white-400) !important;
  border: 1px solid var(--ck-color-base-border) !important;
}
.react-tagsinput-tag {
  background-color: var(--clr-F6F6F6-252525) !important;
  border: none !important;
  color: var(--t-clr-dark-400) !important;
  display: inline-flex !important;
  gap: 5px !important;
}
.react-tagsinput-input {
  width: 100% !important;
}

/* video description textarea */
.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover {
  background: var(--clr-F6F6F6-252525) !important;
}

/* react-bootstrap-typeahead */
.rbt-input-hint {
  color: rgb(240 240 240 / 76%) !important;
}

/* Popover */
.popover {
  background-color: var(--bg-clr-popover);
}
.popover * {
  color: var(--text-clr-popover);
}
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^='top'] .arrow::after {
  border-top-color: var(--bg-clr-popover);
}

/* .tooltip > div.tooltip-inner {
  background-color: red !important;
  color: green !important;
}

.tooltip.show {
  opacity: 1 !important;
}

.tooltip > div.arrow::before {
  border-bottom-color: yellow!important;
  color: blue !important;
} */

.card {
  background-color: var(--clr-light-400) !important;
  border-radius: 26px !important;
}

.container-xxl {
  width: 100%;
  /* padding-right: 15px;
  padding-left: 15px; */
  margin-right: auto;
  margin-left: auto;
}

/* SELECT RANGE START */
/* === range theme and appearance === */

input[type='range'].custom-slide-range {
  color: var(--clr-primary-400);
  --thumb-height: 21px;
  --track-height: 1px;
  --track-color: rgba(0, 0, 0, 0.2);
  --clip-edges: 0.125em;
  font-size: 1.5rem;
  width: 12.5em;
  position: relative;
  background: #fff0;
  overflow: hidden;
}

/* === range commons === */

input[type='range'].custom-slide-range:active {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

input[type='range'].custom-slide-range:disabled {
  filter: grayscale(1);
  opacity: 0.3;
  cursor: not-allowed;
}

/* === WebKit specific styles === */
input[type='range'].custom-slide-range,
input[type='range'].custom-slide-range::-webkit-slider-runnable-track,
input[type='range'].custom-slide-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 1.125em;
}

input[type='range'].custom-slide-range::-webkit-slider-runnable-track {
  position: relative;
}

input[type='range'].custom-slide-range::-webkit-slider-thumb {
  background: #fff !important;
  border: 2px solid #939393 !important;
  width: 20px !important;
  height: 20px !important;
  border-radius: 100% !important;
  position: relative;
  top: 3px;
}
input[type='range'].custom-slide-range::-webkit-slider-thumb {
  --thumb-radius: calc((var(--thumb-height) * 0.5) - 1px);
  --clip-top: calc((var(--thumb-height) - var(--track-height)) * 0.5 - 0.5px);
  --clip-bottom: calc(var(--thumb-height) - var(--clip-top));
  --clip-further: calc(100% + 1px);
  --box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0
    100vmax currentColor;

  width: var(--thumb-width, var(--thumb-height));
  background: linear-gradient(currentColor 0 0) scroll no-repeat left center /
    50% calc(var(--track-height) + 1px);
  background-color: currentColor;
  box-shadow: var(--box-fill);
  border-radius: var(--thumb-width, var(--thumb-height));

  filter: brightness(100%);
  -webkit-clip-path: polygon(
    100% -1px,
    var(--clip-edges) -1px,
    0 var(--clip-top),
    -100vmax var(--clip-top),
    -100vmax var(--clip-bottom),
    0 var(--clip-bottom),
    var(--clip-edges) 100%,
    var(--clip-further) var(--clip-further)
  );
  clip-path: polygon(
    100% -1px,
    var(--clip-edges) -1px,
    0 var(--clip-top),
    -100vmax var(--clip-top),
    -100vmax var(--clip-bottom),
    0 var(--clip-bottom),
    var(--clip-edges) 100%,
    var(--clip-further) var(--clip-further)
  );
}

input[type='range'].custom-slide-range:hover::-webkit-slider-thumb {
  filter: brightness(var(--brightness-hover));
  cursor: -webkit-grab;
  cursor: grab;
}

input[type='range'].custom-slide-range:active::-webkit-slider-thumb {
  filter: brightness(var(--brightness-down));
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

input[type='range'].custom-slide-range::-webkit-slider-runnable-track {
  background: linear-gradient(var(--track-color) 0 0) scroll no-repeat center /
    100% calc(var(--track-height) + 1px);
}

input[type='range'].custom-slide-range:disabled::-webkit-slider-thumb {
  cursor: not-allowed;
}

/* === Firefox specific styles === */
input[type='range'].custom-slide-range,
input[type='range'].custom-slide-range::-moz-range-track,
input[type='range'].custom-slide-range::-moz-range-thumb {
  -moz-appearance: none;
  appearance: none;
  -moz-transition: all ease 100ms;
  transition: all ease 100ms;
  height: var(--thumb-height);
}

input[type='range'].custom-slide-range::-moz-range-track,
input[type='range'].custom-slide-range::-moz-range-thumb,
input[type='range'].custom-slide-range::-moz-range-progress {
  background: #fff0;
}

input[type='range'].custom-slide-range::-moz-range-thumb {
  background: #fff;
  border: 2px solid #939393;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  cursor: grab;
}

input[type='range'].custom-slide-range::-moz-range-track {
  width: 100%;
  background: #cbcbcb;
}

input[type='range'].custom-slide-range::-moz-range-progress {
  -moz-appearance: none;
  appearance: none;
  background: var(--clr-primary-400);
  transition-delay: 30ms;
}

input[type='range'].custom-slide-range::-moz-range-track,
input[type='range'].custom-slide-range::-moz-range-progress {
  height: 2px;
  border-radius: 100%;
}

/* close button */
.close {
  color: var(--clr-close-x);
  text-shadow: none;
  opacity: 0.5;
  font-size: var(--fs-600);
  font-weight: var(--fs-400);
}
.close:hover {
  color: var(--clr-close-x);
}
.close:focus {
  outline: none !important;
}

.description-text-style-same > * {
  font-size: var(--fs-300);
  color: var(--clr-dark-100);
  font-weight: var(--fw-400);
}

.btn-ripple {
  display: inline-block;
  position: relative;
  overflow: hidden;
  /* transition: all ease-in-out 0.5s; */
}

.btn-ripple::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 25%;
  height: 100%;
  width: 50%;
  background-color: var(--clr-icon);
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  transition: all ease-in-out 0.4s;
  transform: scale(0, 0);
}

.btn-ripple:active::after {
  padding: 0;
  margin: 0;
  opacity: 0.2;
  transition: 0s;
  transform: scale(5, 5);
}

.btn-link:hover{
  opacity: 0.8 !important;
}

.hover-trigger-container {
  transition: visibility 0.1s;
}

.hover-trigger-container:hover .trigger-element {
  visibility: visible;
}
.hover-trigger-container .trigger-element {
  visibility: hidden;
}
.hover-trigger-container .trigger-element:focus {
  visibility: visible;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* xxl (extra extra large) */
@media (min-width: 1400px) {
  .container-xxl {
    max-width: 1320px;
  }
}

/* xl (extra large) */
@media (min-width: 1200px) {
}

/* lg (large) */
@media (min-width: 992px) {
}

/* md (medium) */
@media (min-width: 768px) {
}

/* sm (small) */
@media (min-width: 576px) {
}

/* xs (extra small) */
@media (max-width: 576px) {
  [data-shaka-player-container] .shaka-vertical-volume-container {
    display: none;
  }
  .shaka-overflow-menu,
  .shaka-settings-menu {
    max-height: 115px !important;
  }
}
