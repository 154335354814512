.upload-video .fas,
.upload-video svg {
  font-size: 200px;
}

#upload-visibility .instruction {
  background-color: var(--clr-F6F6F6-252525);
}

#upload-details .upload-box {
  background-color: var(--clr-F6F6F6-252525);
}

#video-published-modal .social-icon-link {
  opacity: 1;
  transition: opacity 0.2s ease-out;
  margin-bottom: 10px;
}
#video-published-modal .social-icon-link:hover {
  opacity: 0.6;
}

#ck-description .ck-editor__editable {
  background-color: red;
}

.upload-video-thumbnail {
  max-width: 140px;
  width: 100%;
  aspect-ratio: 16/9;
  cursor: pointer;
  opacity: 0.5;
  margin-right: 10px;
}

.upload-video-thumbnail:hover {
  opacity: 1;
}
