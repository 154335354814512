@import '../../../theme/root-variables.css';

.item {
  /* width: 200px; */
}
.category-item img {
  background: var(--clr-section-heading-circle-icon) none repeat scroll 0 0;
  border-radius: 50px;
  /* box-shadow: 0 0 2px #ccc; */
  /* object-fit: scale-down; */
  object-fit: cover;
  transform: scale(1);
  height: 36px;
  width: 36px;
}
/* .category-item:hover img {
	transform: scale(1.09);
	transition-duration: 0.4s;
} */
.slick-list {
  padding: 0 20% 0 0 !important;
}

.top-category .slick-slider {
  padding: 0 50px !important;
}

.category-item {
  background: var(--clr-light-400) none repeat scroll 0 0;
  border: 1px solid var(--category-card-border);
  border-radius: var(--radius-5);
  box-shadow: 0 3px 6px var(--shadow);
  margin: 6px 6px;
  padding: 0 12px;
  text-align: center;
}
.top-category .category-item {
  height: 60px;
  display: flex;
  align-items: center;
}
.category-item h6 {
  font-size: var(--fs-400);
  margin: 0px;
  /* overflow: hidden;
  text-overflow: ellipsis; */
  white-space: nowrap;
  color: var(--t-clr-dark-400);
}
.category-item p {
  font-size: 11px;
  font-weight: 500;
  margin: 0;
}
