@import './root-variables.css';

/* COLORS CLASSES */
.text-clr-dark-100 {
  color: var(--clr-dark-100);
}
.bg-clr-dark-100 {
  background-color: var(--clr-dark-100);
}
.text-clr-dark-200 {
  color: var(--clr-dark-200);
}
.text-clr-primary-400 {
  color: var(--clr-primary-400);
}
.bg-clr-dark-200 {
  background-color: var(--clr-dark-200);
}
.text-clr-dark-300 {
  color: var(--clr-dark-300);
}
.text-clr-blue-400 {
  color: var(--clr-blue-100);
}
.bg-clr-dark-300 {
  background-color: var(--clr-dark-300);
}
.bg-clr-primary-400 {
  background-color: var(--clr-primary-400);
}

.text-link {
  color: var(--clr-link) !important;
}

/* FONT SIZE CLASSES */
.fs-600 {
  font-size: var(--fs-600);
}
.fs-500 {
  font-size: var(--fs-500);
}
.fs-400 {
  font-size: var(--fs-400);
}
.fs-300 {
  font-size: var(--fs-300);
}
.fs-200 {
  font-size: var(--fs-200) !important;
}

/* FONT WEIGHT */
.fw-800 {
  font-weight: 800;
}
.fw-700 {
  font-weight: 700;
}
.fw-600 {
  font-weight: 600;
}
.fw-500 {
  font-weight: 500;
}
.fw-400 {
  font-weight: 400;
}
.fw-300 {
  font-weight: 300;
}
.fw-200 {
  font-weight: 200;
}
.fw-100 {
  font-weight: 100;
}

/* borders */
.border-dropdown {
  border: 1px solid var(--clr-border-dropdown);
}

/* hr or dividers */
.clr-white-card-hr {
  border-top: 1px solid var(--clr-white-card-hr) !important;
}

.text-clr-dark-100 {
  color: var(--t-clr-dark-400);
}

.cursor-pointer {
  cursor: pointer;
}

.btn-rounded {
  padding: 5px !important;
  /* width: 40px; */
  /* height: 40px; */
  border-radius: 50% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-ellipse {
  text-overflow: ellipsis;
}

/* height */
.h-navbar {
  height: var(--h-navbar);
}

.min-h-without-navbar-footer {
  min-height: calc(100vh - var(--h-navbar) - var(--h-thin-footer));
}

.min-h-without-navbar {
  min-height: calc(100vh - var(--h-navbar));
}

.text-line-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: anywhere;
}

.text-line-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: anywhere;
}

.text-line-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: anywhere;
}

.icon {
  fill: var(--clr-icon);
}

.icon-hover:hover {
  fill: var(--clr-icon-hover);
  cursor: pointer;
}

.w-mins-dot-btn {
  width: var(--w-title-mins-icon);
}

.studio-card {
  background: var(--clr-light-400);
  display: flex;
  flex-direction: column;
  border-radius: 26px;
  box-shadow: 0 3px 10px var(--clr-dark-200);
  transition: all 0.2s ease-in-out;
}

.min-h-section{
  min-height: 400px;
}