#studio-content-page .filter {
  position: absolute;
  right: 16px;
  top: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

#studio-content-page .table-responsive {
  min-height: calc(260px + 150px) !important;
}
.delete-btn:hover {
  opacity: 0.5;
  cursor: pointer;
}

.visibility-dropdown > .nav-link {
  padding: 0;
}
.visibility-dropdown {
  background: var(--clr-change-visibility);
  box-shadow: 0px 3px 10px #00000029;
  border-radius: 15px;
  min-width: 340px;
  position: absolute;
  /* top: -50%; */
  left: -50%;
  z-index: 10;
}
.mobile-view-video-edit {
  display: none;
}
.video-edit-web-view {
  display: block;
}
@media only screen and (max-width: 991px) {
  .mobile-view-video-edit {
    display: block;
  }
  .video-edit-web-view {
    display: none;
  }
}
