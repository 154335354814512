.reward-level-card {
  background: linear-gradient(
    120deg,
    var(--clr-primary-400) 0%,
    var(--clr-primary-500) 100%
  ) !important;
}

.reward-metric-row .card svg {
  width: 25px;
  height: 25px;
  fill: #636363;
}

.reward-metric-row .card h6 {
  color: var(--clr-text-1);
}
