@import '../../theme/root-variables.css';

#create-channel-content {
  background: var(--clr-light-400);
  min-height: 60vh;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  padding: 2rem 4vw;
  border-radius: 26px;
  box-shadow: 0 3px 10px var(--clr-dark-200);
  margin-bottom: 20px;
}

#create-channel-content > img {
  margin: 10px auto;
}

#create-channel-content > h2 {
  /* color: var(--clr-dark-300); */
  /* font-size: var(--fs-500); */
  font-weight: 600;
}
#create-channel-content > p {
  font-size: var(--fs-300);
  color: var(--fs-dark-100);
  margin: 12px 0;
}

#create-channel-content .term-check-div {
  display: flex;
  align-items: flex-start;
}
#create-channel-content .term-check-div > p {
  font-size: var(--fs-300);
  color: var(--fs-dark-100);
}

#create-channel-content .term-check-div > p > span {
  color: var(--clr-primary-400);
  font-weight: 600;
}
