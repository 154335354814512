@import '../../../theme/root-variables.css';

.no-after::after {
  display: none !important;
}

.main-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 1rem; */
}

.main-title > .left {
  display: flex;
  align-items: center;
}

.main-title .left > .circle {
  background-color: var(--clr-section-heading-circle-icon);
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 10px;
}

.main-title .left img,
.main-title .left svg {
  /* background-color: #ededed; */
  /* margin-right: 0.5rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23px;
  height: 23px;
  fill: #fc5677;
  /* width: 40px;
	height: 40px; */
  /* border-radius: 50%; */
  /* object-fit: scale-down; */
}

.main-title .left > h6 {
  color: var(--t-clr-dark-400);
  font-size: var(--fs-500);
  font-weight: 600;
  margin-bottom: 0;
}
.top-category .main-title > h6 {
  margin-bottom: 10px;
}
.right-action-link span {
  background: #fff none repeat scroll 0 0;
  border-radius: 50px;
  box-shadow: 0 0 6px #ececec;
  display: inline-block;
  height: 20px;
  line-height: 19px;
  margin: 0 0 0 3px;
  text-align: center;
  width: 20px;
}

.view-all-text {
  font-size: var(--fs-400);
  font-weight: 400;
  color: var(--t-clr-dark-400);
  cursor: pointer;
  margin-bottom: 0;
}

.view-all-text:hover {
  color: #fc5e74;
}
