@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import './theme/root-variables.css';
@import './custom.css';
/*
Template Name: VIDOE - Video Streaming Website HTML Template
Author: Askbootstrap
Author URI: https://themeforest.net/user/askbootstrap
Version: 1.0
*/
/*
    -- Global Rules
    -- Customize Slick Slider
    -- Tag Cloud
    -- Custom Colors with higher specificity
*/
/* Global Rules */

@font-face {
  font-family: 'Font Awesome 5 free';
  src: url('./webfonts/fa-solid-900.ttf');
}

:root {
  --input-padding-x: 0.75rem;
  --input-padding-y: 0.75rem;
}

html {
  position: relative;
  min-height: 100%;
}
#project-root {
  background-color: var(--secondary-main) !important;
  min-height: 100vh;
}
body {
  /* background-color: #fafafa !important; */
  color: #888 !important;
  font-size: 13px !important;
  height: 100%;
  font-family: 'Open Sans', sans-serif !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  /* border-radius: 10px;  */
  /* -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3); */
  background-color: #cdcdcd;
}

#wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* Add bottom rule to fix double padding issue in react thin footer in single channel page */
  flex-direction: column;
}
#wrapper #content-wrapper {
  /* overflow-x: hidden; */
  width: 100%;
}
#wrapper #content-wrapper .container-fluid {
  /* padding: 30px 30px 30px 255px; */
  /* padding: 0px 0px 30px 225px; */
  padding: 0px 0px 0px 225px;
  transition: all 0.4s ease;
}
.sidebar-toggled #wrapper #content-wrapper .container-fluid {
  /* padding: 30px 30px 30px 121px; */
  /* padding: 0px 0px 30px 91px; */
  padding: 0px 0px 0px 91px;
}

body.fixed-nav #content-wrapper {
  margin-top: 56px;
  padding-left: 90px;
}
body.fixed-nav.sidebar-toggled #content-wrapper {
  padding-left: 0;
}
@media (min-width: 768px) {
  body.fixed-nav #content-wrapper {
    padding-left: 225px;
  }
  body.fixed-nav.sidebar-toggled #content-wrapper {
    padding-left: 90px;
  }
}

.carousel-login-card {
  padding: 11px 0;
}

.smaller {
  font-size: 0.7rem;
}
.o-hidden {
  overflow: hidden !important;
}
.z-0 {
  z-index: 0;
}
.z-1 {
  z-index: 1;
}

/*
* These rules (.intro-page) are not part of the theme. 
* They are valid for separate info page 
* and should be removed in production app.
*/

/*.intro-page img {
    border-radius: 4px !important;
    box-shadow: 0 0 21px #ccc;
}
.intro-page p a {
    color: #000 !important;
    font-weight: bold;
    text-transform: uppercase;
}*/

/* icon wrapper circle button  */

.icon-circle-wrapper {
  border-radius: 50%;
  background-color: transparent;
  aspect-ratio: 1;
  padding: 8px;
  /* width: 30px;
  height: 30px; */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  /* box-shadow: 0 3px 8px var(--clr-dark-200); */
  outline: none;
  border: 1px solid transparent;
}

.icon-circle-wrapper:hover {
  /* opacity: 0.6; */
}
.icon-circle-wrapper:active {
  border: 1px solid #ccc;
  opacity: 0.6;
  background-color: var(--clr-bg-icon-btn);
  border-color: var(--clr-border-icon-btn);
}

.icon-circle-wrapper:hover {
  /* color: #212529; */
  /* background-color: var(--clr-bg-icon-btn);
  border-color: var(--clr-border-icon-btn); */
  /* box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); */
  /* outline: none; */
}
.icon-circle-wrapper:focus,
.icon-circle-wrapper:active {
  /* color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); */
  /* outline: none; */
}

.card-login {
  max-width: 25rem;
}
.card-register {
  max-width: 40rem;
}
.form-label-group {
  position: relative;
}
.form-label-group > input,
.form-label-group > label {
  padding: var(--input-padding-y) var(--input-padding-x);
  height: auto;
}
.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}
.form-label-group input:-ms-input-placeholder {
  color: transparent;
}
.form-label-group input::-ms-input-placeholder {
  color: transparent;
}
.form-label-group input::placeholder {
  color: transparent;
}
.form-label-group input:not(:placeholder-shown) {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3);
}
.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
}

/* Customize Bootstrap */
.border-none {
  border: none !important;
}

.text-primary {
  color: var(--clr-primary-400) !important;
}
.progress-bar {
  background-color: var(--clr-primary-400) !important;
}
.bg-success,
.btn-success,
.btn-outline-success:hover,
.badge-success {
  border-color: transparent !important;
  background: var(--clr-success-400);
  color: #fff !important;
  /* Old browsers */
  /* background: -moz-linear-gradient(
    -45deg,
    var(--clr-success-400) 1%,
    #56e0cb 100%
  ); */
  /* FF3.6-15 */
  /* background: -webkit-linear-gradient(
    -45deg,
    var(--clr-success-400) 1%,
    #56e0cb 100%
  ); */
  /* Chrome10-25,Safari5.1-6 */
  /* background: linear-gradient(135deg, var(--clr-success-400) 1%, #56e0cb 100%); */
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  /* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='var(--clr-success-400)', endColorstr='#56e0cb',GradientType=1 ); */
  /* IE6-9 fallback on horizontal gradient */
}
.btn-outline-success {
  border-color: var(--clr-success-400) !important;
  color: var(--clr-success-400) !important;
}
.text-success {
  color: var(--clr-success-400) !important;
}
.bg-warning,
.btn-warning,
.btn-outline-warning:hover,
.badge-warning {
  border-color: transparent !important;
  background: #ffc107;

  /* Old browsers */
  /* background: -moz-linear-gradient(-45deg, #ffc107 1%, #fffc07 100%); */
  /* FF3.6-15 */
  /* background: -webkit-linear-gradient(-45deg, #ffc107 1%, #fffc07 100%); */
  /* Chrome10-25,Safari5.1-6 */
  /* background: linear-gradient(135deg, #ffc107 1%, #fffc07 100%); */
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  /* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffc107', endColorstr='#fffc07',GradientType=1 ); */
  /* IE6-9 fallback on horizontal gradient */
}
.btn-outline-warning {
  border-color: #ffc107;
  color: #ffc107;
}
.text-warning {
  color: #ffc107 !important;
}
.bg-danger,
.btn-danger,
.btn-outline-danger:hover,
.badge-danger {
  border-color: transparent !important;
  /* background: #ff253a; */
  background: var(--clr-danger-400);
  color: #fff !important;
  /* Old browsers */
  /* background: -moz-linear-gradient(-45deg, #ff253a 0%, #ff8453 100%); */
  /* FF3.6-15 */
  /* background: -webkit-linear-gradient(-45deg, #ff253a 0%, #ff8453 100%); */
  /* Chrome10-25,Safari5.1-6 */
  /* background: linear-gradient(135deg, #ff253a 0%, #ff8453 100%); */
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  /* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff253a', endColorstr='#ff8453',GradientType=1 ); */
  /* IE6-9 fallback on horizontal gradient */
}
.btn-outline-danger {
  border-color: #ff253a !important;
  color: #ff253a !important;
}
.text-danger {
  color: #ff253a !important;
}
.bg-secondary,
.btn-secondary,
.btn-outline-secondary:hover,
.badge-secondary {
  border-color: transparent !important;
  background: #9ba8aa;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #9ba8aa 0%, #92b4d2 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #9ba8aa 0%, #92b4d2 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #9ba8aa 0%, #92b4d2 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9ba8aa', endColorstr='#92b4d2',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */
}
.btn-outline-secondary {
  border-color: #9ba8aa;
  color: #9ba8aa;
}
.text-secondary {
  color: #9ba8aa !important;
}
.bg-info,
.btn-info,
.btn-outline-info:hover,
.badge-info {
  border-color: transparent !important;
  background: #17a2b8;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #17a2b8 1%, #30def9 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #17a2b8 1%, #30def9 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #17a2b8 1%, #30def9 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#17a2b8', endColorstr='#30def9',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */
}
.btn-outline-info {
  border-color: #17a2b8;
  color: #17a2b8;
}
.text-info {
  color: #17a2b8 !important;
}
.bg-dark,
.btn-dark,
.btn-outline-dark:hover,
.badge-dark {
  border-color: transparent !important;
  background: #171b20;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #171b20 1%, #343a40 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #171b20 1%, #343a40 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #171b20 1%, #343a40 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#171b20', endColorstr='#343a40',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */
}
.btn-outline-dark {
  border-color: #171b20;
  color: #171b20;
}
.text-dark {
  color: #171b20 !important;
}

a {
  /* color: var(--clr-dark-300) !important; */
  text-decoration: none !important;
}

.dropdown-divider {
  border-top: 1px solid var(--clr-dropdown-hr) !important;
  opacity: 0.3;
}

hr {
  border-top: 1px solid var(--clr-sidebar-hr) !important;
  opacity: 0.12;
}

a:hover,
h1,
h2,
h3,
h4,
h5,
h6,
td,
th {
  color: var(--clr-dark-300);
}

.badge,
.alert {
  border-radius: 2px !important;
}

/* Video Card */
.video-block .mb-3 {
  margin-bottom: 30px !important;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  /* html{
    font-size: 70%;
  } */
  #wrapper #content-wrapper .container-fluid {
    /* padding-bottom: 15px !important; */
    padding-bottom: 0px !important;
    /* padding-left: 108px; */
    padding-left: 0;
    /* padding-right: 15px !important; */
    padding-right: 0px !important;
    /* padding-top: 1rem !important; */
    padding-top: 0rem !important;
  }
  .sidebar-toggled #wrapper #content-wrapper .container-fluid {
    /* padding-left: 15px; */
    padding-left: 0px;
  }
}
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  #wrapper #content-wrapper .container-fluid {
    /* padding-bottom: 15px !important; */
    padding-left: 90px;
    /* padding-right: 15px !important; */
    /* padding-top: 1rem !important; */
  }
  .sidebar-toggled #wrapper #content-wrapper .container-fluid {
    /* padding-left: 15px; */
    padding-left: 0px;
  }

  .sidebar-toggled #wrapper #content-wrapper .container-fluid {
    /* padding-left: 15px; */
  }

  .container {
    max-width: 100%;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  #wrapper #content-wrapper .container-fluid {
    /* padding-bottom: 15px !important; */
    /* padding-left: 243px; */
    /* padding-right: 15px !important; */
    /* padding-top: 1rem !important; */
  }
  .sidebar-toggled #wrapper #content-wrapper .container-fluid {
    /* padding-left: 105px; */
    padding-left: 90px;
  }
  .container {
    max-width: 100%;
  }
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  #wrapper #content-wrapper .container-fluid {
    /* padding-bottom: 15px !important; */
    /* padding-left: 243px; */
    padding-left: 225px;
    /* padding-right: 15px !important; */
    /* padding-top: 1rem !important; */
  }

  .sidebar-toggled #wrapper #content-wrapper .container-fluid {
    /* padding-left: 105px; */
    padding-left: 90px;
  }

  .container {
    max-width: 100%;
  }
}
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* Slick Slider */
/* Customize Slick Arrows*/
.custom-slick-btn {
  background: var(--clr-section-heading-circle-icon);
  box-shadow: 0 3px 4px var(--shadow);
  /* color: var(--clr-dark-100); */
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  line-height: 1rem;
  border-radius: 50px;
  opacity: 0.8;
  z-index: 1;
  font-size: 2rem;
}
.custom-slick-btn img {
  width: 35px;
  height: 35px;
}
.custom-slick-btn:hover {
  color: #545758;
}

.custom-slick-next {
  position: absolute;
  top: 30%;
  right: 10px;
  display: block !important;
  transition: all 0.45s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
}
.custom-slick-prev {
  position: absolute;
  top: 30%;
  left: 10px;
  display: block !important;
  transition: all 0.45s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
}

/* Customize Slick Dots*/
.slick-slide img {
  display: initial !important;
}
/* Tag Cloud */
.single-video-info-content .tag-cloud-link {
  background: #ccc none repeat scroll 0 0;
  border-radius: 2px;
  color: #fff !important;
  display: inline-block;
  margin: 2px 0;
  padding: 4px 13px;
}
.single-video-info-content .tag-cloud-link:hover {
  background: #000 none repeat scroll 0 0;
  color: #fff !important;
}

/* Custom Colors with higher specificity*/

/* Custom text succes*/
.text-success-custom,
.single-video-left a.text-success-custom {
  color: var(--clr-success-400) !important;
}

a.text-success-custom:hover,
a.text-success-custom:active,
.single-video-left a.text-success-custom:hover,
.single-video-left a.text-success-custom:active {
  color: var(--clr-success-400) !important;
}

/* Custom text danger*/
.text-danger-custom {
  color: #ff253a !important;
}

/* CUstom text primary */
.text-primary-custom,
.category-item a,
/* .single-video-left a, */
.right-action-link,
.terms a,
.osahan-close a,
.login-main-left a,
.sidebar-list-link {
  color: var(--clr-primary-400) !important;
}

/* Gray hover with higher specificity */
/* .category-item a:hover, */
/* .video-title a:hover, */
/* .single-video-left a:hover, */
/* .single-video-left a:active, */
.right-action-link:hover,
.right-action-link:active,
.single-video-info-content a:hover,
.single-video-info-content a:active {
  /* color: #333 !important; */
  color: var(--clr-dark-300) !important;
}

/* Black hover with higher specificity */
.terms a:hover,
.terms a:active,
.osahan-close a:hover,
.osahan-close a:active,
.login-main-left a:hover,
.login-main-left a:active,
.sidebar-list-link:hover,
.sidebar-list-link:active {
  color: #000 !important;
}

.live-indicator {
  animation: pulse 1s infinite alternate;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
@media screen and (max-width: 575px) {
  .upload-image-verifychannel {
    margin-top: 10px;
  }
}
@media screen and (max-width: 600px) {
  .dynamic-text {
    font-size: 10px;
  }
}
