@import '../../../theme/root-variables.css';

.channels-card {
  background: var(--clr-light-400) none repeat scroll 0 0;
  border-radius: 2px;
  box-shadow: 0 3px 8px var(--shadow);
  padding: 24px 12px;
  position: relative;
  text-align: center;
  transition-duration: 0.4s;
}
.channels-card .channels-card-image > a {
  display: flex;
  justify-content: center;
  margin: 1px 0 12px;
}

.channels-card::after {
  background: #dcdcdc none repeat scroll 0 0;
  border-radius: 0 0 5px 6px;
  bottom: -4px;
  content: '';
  height: 7px;
  left: 15px;
  position: absolute;
  right: 15px;
  z-index: -1;
}
.channels-card:hover::after {
  background: #cccccc none repeat scroll 0 0;
}
.channels-title a {
  color: var(--t-clr-dark-400) !important;
  font-weight: 500;
  text-decoration: none;
}
.channels-view {
  color: var(--clr-dark-100);
}
.channels-card-image .btn {
  font-size: 12px;
  padding: 3px 10px;
}
.channels-card-image .btn strong {
  margin: 0 0 0 5px;
}
.channels-card-image img {
  background: #fff none repeat scroll 0 0;
  border-radius: 50px;
  box-shadow: 0 0 2px #ccc;
  height: 80px;
  /* margin: 1px 0 8px; */
  object-fit: scale-down;
  transform: scale(1);
  width: 80px;
}
.channels-card-image-btn {
  margin: 3px 0 7px;
}




@media screen and (max-width: 575px) {
.channels-card-image-btn.web-subscribe-btn{
display:none;
  }
.channels-card-image-btn.mob-subscribe-btn{
    display: block;
          }
.channels-card .channels-card-image > a {
            display: flex;
            justify-content: center;
            margin: 0px;
        }


.channels-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
}

.channels-card-body {
  text-align: left;
}
.channels-card-image {
  margin-right: 10px;
}
.channels-card-left-section{
  display: flex;
  align-items: center;
}

}

@media screen and (min-width: 575px) {
  .channels-card-image-btn.web-subscribe-btn{
    display:block;
      }
.channels-card-image-btn.mob-subscribe-btn{
display: none;
      }
}