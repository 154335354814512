@import '../../theme/root-variables.css';

.top-mobile-search {
  display: none;
}
.top-mobile-search {
  margin: -16px -15px 19px -18px;
}
.top-mobile-search input {
  border-radius: 0;
  box-shadow: none !important;
}
.top-mobile-search .btn {
  border-radius: 0;
  font-size: inherit;
}
@media (max-width: 575.98px) {
  .top-mobile-search {
    display: block;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .top-mobile-search {
    display: block;
  }
}

.carousel-control-next {
  width: clamp(10px, 5vw, 60px);
  height: clamp(10px, 5vw, 60px);
  border-radius: 100%;
  background: #212122;
  margin: auto;
  right: 3%;
}

.carousel-control-prev {
  width: clamp(10px, 5vw, 60px);
  height: clamp(10px, 5vw, 60px);
  border-radius: 100%;
  background: #212122;
  margin: auto;
  left: 3%;
}

/* react slick carousel */
.home-carousel-root {
  box-shadow: 1px 2px 11px 2px var(--shadow);
  overflow: hidden;
  border-radius: var(--radius-10);
}

.home-carousel {
  position: relative;
  display: flex;
  aspect-ratio: 15/3;
  min-height: 120px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.home-carousel .content .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(45deg, black, transparent);
  height: 100%;
  width: 100%;
}

.home-carousel .content {
  background-size: cover;
  z-index: 100000;
  background-position: center;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 2rem 0;
  margin: 0 !important;
}
.home-carousel h6 {
  color: white;
  text-align: center;
  margin-bottom: 10px;
  font-size: var(--fs-500);
  margin-left: auto;
  margin-right: auto;
  width: 95%;
}

.home-carousel p {
  color: #8d8c89;
  text-align: center;
  font-size: var(--fs-300);
  margin: 0;
}

.custom-home-slick-btn {
  background: transparent;
  margin: 0;
  padding: 0;
  border: 0 !important;
  outline: 0 !important;
  opacity: 0.8;
  border-radius: 50%;
  z-index: 1;
  width: 50px;
}

.custom-home-slick-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  /* display: block !important; */
  transition: all 0.45s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
}
.custom-home-slick-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  transition: all 0.45s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
}

/* react slick (slider) */

/* dots */
.slick-dots-custom {
  position: absolute;
  bottom: 8px;
}

@media (max-width: 500px) {
  .slick-dots-custom {
    display: none !important;
  }
}

.slick-dots button {
  border-radius: 50px;
  width: 8px !important;
  height: 8px !important;
  background: #dededee4 !important;
}
.slick-dots li.slick-active button:before {
  color: transparent !important;
}
.slick-dots li button:before {
  color: transparent !important;
}

.slick-dots li.slick-active button {
  background: linear-gradient(135deg, var(--clr-primary-400) 0%, var(--clr-primary-500) 100%) !important;
  /* background: linear-gradient(135deg, #fff 0%, #fff 100%) !important; */
  width: 12px !important;
  height: 12px !important;
}
.slick-dots li {
  width: 8px !important;
  height: 8px !important;
}

.section-hr {
  border-top: 1px solid var(--clr-section-hr);
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .custom-home-slick-btn img {
    width: 40px;
    height: 40px;
  }
  .home-carousel h6 {
    font-size: var(--fs-400);
  }
  .home-carousel p {
    font-size: var(--fs-200);
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .custom-home-slick-btn img {
    width: 40px;
    height: 40px;
  }
  .home-carousel h6 {
    font-size: var(--fs-300);
  }
  .home-carousel p {
    font-size: var(--fs-200);
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .custom-home-slick-btn img {
    width: 35px;
    height: 35px;
  }
  .slick-dots .slick-active button {
    width: 9px !important;
    height: 9px !important;
  }
  .slick-dots li {
    width: 12px !important;
    height: 12px !important;
  }
  .slick-dots button {
    width: 6px !important;
    height: 6px !important;
  }

  .home-carousel h6 {
    font-size: var(--fs-200);
  }
  .home-carousel p {
    font-size: var(--fs-100);
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .home-carousel p {
    font-size: 0.5rem;
  }
  .home-carousel h6 {
    font-size: var(--fs-400);
  }
  .home-carousel p {
    font-size: var(--fs-200);
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .home-carousel p {
    font-size: 0.6rem;
  }
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .home-carousel p {
    font-size: 0.7rem;
  }
}
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}


@media screen and (max-width: 768px) {
  .top-category.category-item {
    /* Set styles for mobile view */
    margin-right: 10px!important;
    padding: 0px!important;
  }
}



@media screen and (max-width: 575px) {
  .category-item img{
    /* Set styles for mobile view */
   display: none !important;
  }
  .top-category .category-item {
    height: 40px !important;
   
}
.category-item h6{
  margin-left: 0px !important;
}
button.custom-slick-btn.mobile-preview-next {
  display: none !important;
}
button.custom-slick-btn.mobile-preview-prev {
  display: none !important;
}
.top-category.section-padding .slick-slider {
  padding: 0rem  !important;
}
}

 