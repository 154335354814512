/* 
  bootstrap typography
  h1 40px
  h2 32px
  h3 28px
  h4 24px
  h5 20px
  h6 16px
  
  display-1 96px
  display-2 88px
  display-3 72px
  display-4 56px
  lead 20px
  small 10.4px  
  */

:root,
[data-theme='light'] {
  /* COLORS */
  --clr-primary-300: #fd7888;
  --clr-primary-400: #fc6173;
  --clr-primary-500: #fc4080;

  /* sub heading */
  --clr-dark-100: #858585;
  --clr-dark-200: #00000029;
  --clr-dark-300: #4b4b4b;
  /* heading */
  --clr-dark-400: #242424;
  --clr-dark-500: #121212;
  --t-clr-dark-400: var(--clr-dark-400);

  --clr-light-300: #f6f6f6;
  --clr-light-400: #fff;
  --clr-light-500: #ededed;

  --clr-success-400: #4eda92;
  --clr-danger-400: #f32013;
  --clr-blue-400: #005baa;
  --clr-link: #007bff;

  --clr-link: var(--clr-blue-400);

  /* -------- */
  --clr-menubar-g1: var(--clr-primary-400);
  --clr-menubar-g2: var(--clr-primary-500);

  --clr-sidebar-item-svg-hover: #fe5d74;
  --clr-studio-sidebar-avatar: #3a454a;
  --clr-sidebar-g1: #263238;
  --clr-sidebar-g2: #263238;
  /* --clr-sidebar-g1: var(--clr-primary-400);
  --clr-sidebar-g2: var(--clr-primary-500); */

  --clr-sidebar-item-hover-g1: #3a454a;
  --clr-sidebar-item-hover-g2: #3a454a;
  /* --clr-sidebar-item-hover-g1: var(--clr-primary-300);
  --clr-sidebar-item-hover-g2: var(--clr-primary-300); */

  --clr-black2white: var(--clr-dark-400);
  --clr-bg-dropdown: #fff;
  --clr-border-dropdown: #d2d2d2;
  --clr-F6F6F6-252525: var(--clr-light-300);
  /* --gray-box: #f8f9fa; */
  --gray-box: var(--clr-light-300);
  /* --clr-category-circle: var(--clr-light-300); */

  --clr-table-border: #dee2e6;

  --clr-section-heading-circle-icon: var(--clr-light-500);
  --clr-section-hr: #919191;
  --clr-dropdown-hr: #707070;
  --clr-white-card-hr: #858585;
  --clr-sidebar-hr: #fff;

  /* shadow colors */
  --dropdown-shadow: #00000052;
  --shadow: var(--clr-dark-200);
  --secondary-main: #f5f5f5;
  /* --secondary-main: var(--clr-light-300); */
  --search-bar: #eceff0;

  --category-card-border: #fff;
  /* old #fff */
  --header: #263238;
  --header-shadow: #98767a;

  --clr-hover-light-btn: #e2e6ea;
  /* icons color */
  --clr-bg-icon-btn: #e2e6ea;
  --clr-border-icon-btn: #dae0e5;
  --clr-dropdown-icon: #4b4b4b;
  --clr-change-visibility: #fff;
  --clr-text-1: #4b4b4b;

  /* ck editor */
  --ck-color-base-background: #fff;
  --ck-color-base-border: #d2d2d2;
  --ck-color-toolbar-background: #fff;
  --ck-color-toolbar-border: #d2d2d2;
  --ck-color-text: #242424;

  --clr-navbar-search: #263238;
  /* --clr-home-search-btn: #e9ecef; */
  --clr-home-search-btn: #4a5155;
  /* --clr-home-search-btn-border: #ced4da; */
  --clr-home-search-btn-border: #4a5155;
  --clr-search-bar-border: #4a5155;
  --clr-channel-nav: #fff;

  --clr-live-indicator: #2ba3ca;
  --clr-icon: #4b4b4b;
  --clr-icon-hover: #fff;

  --bg-clr-popover: var(--clr-light-400);
  --text-clr-popover: var(--clr-dark-400);
  --clr-close-x: #000;

  /* 
    FONTS 
     - 30px = 1.875rem (banner heading,),
     - 20px = 1.25rem (section header heading)
     - 16px = 1rem (Category, banner subheading, video heading )
     - 14px = 0.875rem (video sub heading (includes views, crypto, 1 month ago), sidebar item text)
     - 12px - 0.75 (sidebar item text)
     - 11px - 0.6875 (sidebar item text)
     - 10px - 0.625(sidebar item text)
  */
  --fs-600: 1.875rem; /* 30px */
  --fs-500: 1.25rem; /* 20px */
  --fs-400: 1rem; /* 16px */
  --fs-300: 0.875rem; /* 14px */
  --fs-200: 0.75rem; /* 12px */
  --fs-100: 0.625rem; /* 10px */
  /*  ----  Home Page ----

Slider
 - title = 20px white 
 - description = 14px #8d8c89 
 - Section title - 20px #242424
 - category text - 16px #242424
 - video card title - 16px #242424
 - video card channel name - 13px 
 - video card views and time - 14px
 - video card duration - 11px
 - channel card - subscribed button - 13px
 - reward card text - 28px
*/

  /* 
    FONT WEIGHT 
  */

  /* BORDER RADIUS */
  --radius-5: 5px;
  --radius-10: 10px;
  --radius-lg: 21px;

  /* Width */
  --w-recommendation-sidebar: 402px;
  /* Height */
  --h-short-content-box: 100px;
  /* --h-navbar: 72px; */
  --h-navbar: 60px;
  /* --h-thin-footer: 15px; */
  --h-thin-footer: 42.3px;
  /* can use min, clamp */
  --w-title-mins-icon: calc(100% - 30px);
}

[data-theme='dark'] {
  --clr-dark-100: #858585;
  --clr-dark-300: #fff;
  --clr-dark-400: #242424;
  --t-clr-dark-400: #fff;

  --clr-light-300: #f6f6f6;
  --clr-light-400: #1c1c1c;
  --clr-link: #7c98ff;

  --clr-menubar-g1: #2e2e2e;
  --clr-menubar-g2: #2e2e2e;

  --clr-studio-sidebar-avatar: #3a454a;
  --clr-sidebar-item-svg-hover: #fe5d74;
  --clr-sidebar-g1: #1c1c1c;
  --clr-sidebar-g2: #1c1c1c;

  --clr-sidebar-item-hover-g1: #3a454a;
  --clr-sidebar-item-hover-g2: var(--clr-primary-500);
  --clr-bg-dropdown: #1c1c1c;
  --clr-border-dropdown: #343434;
  --clr-F6F6F6-252525: #252525;

  --clr-text-1: rgba(255, 255, 255, 0.59);

  --clr-table-border: #343434;

  --clr-black2white: #fff;
  /* --clr-category-circle: #121212; */
  --clr-section-heading-circle-icon: #191919;
  --clr-section-hr: #414141;
  /* white with 12% opacity */
  --clr-white-card-hr: #ffffff1f;
  --shadow: #00000029;
  /* --gray-box: #282828; */
  --gray-box: #252525;

  --secondary-main: var(--clr-dark-500);
  --search-bar: var(--clr-dark-500);

  --category-card-border: #343434;

  --clr-hover-light-btn: #212529;
  --header: #232323;
  --header-shadow: red;
  --clr-close-x: #fff;

  /* ck editor */
  --ck-color-base-background: #1c1c1c;
  --ck-color-base-border: #343434;
  --ck-color-toolbar-background: #1c1c1c;
  --ck-color-toolbar-border: #343434;
  --ck-color-text: #fff;
  --ck-color-list-background: #1c1c1c;

  --clr-channel-nav: var(--clr-dark-500);

  /* icons color */
  --clr-bg-icon-btn: var(--clr-dark-500);
  --clr-border-icon-btn: var(--clr-dark-100);
  --clr-dropdown-icon: #9b9b9b;
  --clr-change-visibility: var(--clr-dark-500);

  --clr-navbar-search: #1c1c1c;
  --clr-home-search-btn: #343434;
  --clr-home-search-btn-border: #343434;
  --clr-search-bar-border: #343434;

  --clr-icon: #fff;
  --clr-icon-hover: #4b4b4b;

  --bg-clr-popover: var(--clr-light-400);
  --text-clr-popover: #fff;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  #project-root {
    --fs-600: 0.6rem;
    /* --fs-400: 0.3rem; */
  }
}
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  #project-root {
    --fs-600: 1rem;
    /* --fs-400: 0.5rem; */
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  #project-root {
    --fs-600: 1.2rem;
    /* --fs-400: 0.6rem; */
  }
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  #project-root {
    --fs-600: 1.5rem;
    /* --fs-400: 0.7rem; */
  }
}
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
