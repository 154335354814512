#shorts-page .short-container .infinite-scroll-component {
  /* max-height: 85vh; */
  overflow-y: scroll !important;
  -ms-scroll-snap-type: y mandatory;
  scroll-snap-type: y mandatory;
  overflow-x: hidden !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
#shorts-page .short-container .infinite-scroll-component::-webkit-scrollbar {
  display: none;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.short-card .header {
  position: absolute;
  top: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
  z-index: 1;
}

.short-card .big-play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation-timing-function: ease-out;
  z-index: 1;
  opacity: 0;
  padding: 12px;
  background-color: rgba(0, 0, 0, 0.337);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes big-play-btn {
  from {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  to {
    opacity: 0;
    transform: translate(-50%, -50%) scale(2);
  }
}

/* #shorts-page .container {
  flex-basis: 50%;
  max-height: 100vh;
  overflow-y: scroll;
  border: 1px solid gray;
  -ms-scroll-snap-type: y mandatory;
  scroll-snap-type: y mandatory;

}
#shorts-page .container.proximity {
  -ms-scroll-snap-type: y proximity;
  scroll-snap-type: y proximity;
} */

#shorts-page .short-card {
  display: flex;
  align-items: end;
  position: relative;
  scroll-snap-align: start;
}

#shorts-page .short-card > .video-container {
  position: relative;
  /* width: 300px; */
  min-height: 525px;
  height: calc(100vh - var(--h-navbar) - 10vh);
  aspect-ratio: 9/16;
  /* height: 85vh; */
  overflow: hidden;
  border-radius: var(--radius-lg);
}

/* #shorts-page .short-card > .video-container > img {
  border-radius: 10px;
  height: 85vh;
} */
#shorts-page .content {
  background-color: transparent;
  /* background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.4), transparent); */
  border-radius: var(--radius-10);
  overflow: hidden;
  position: absolute;
  padding: 0 0.5rem 1rem 0.5rem;
  bottom: 0;
  width: 100%;
  /* height: var(--h-short-content-box); */
}

#shorts-page .content > .short-title {
  color: white;
  font-size: var(--fs-400);
  font-weight: 600;
  text-shadow: 1px 2px var(--clr-dark-400);
}

#shorts-page .content > .profile {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

#shorts-page .content > .profile img {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  margin-right: 0.5rem;
}

#shorts-page .content > .profile a {
  color: white;
  font-size: var(--fs-300);
  font-weight: 600;
}

#shorts-page .action-button-container {
  margin-left: 10px;
}

#shorts-page .action-button-container p {
  /* text-shadow: 1px 2px var(--clr-dark-400); */
  font-weight: 500;
}

/* #shorts-page .btn-shorts-circle:focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
} */

/* #shorts-page .circle-btn > p {
  text-align: center;
  margin-top: 4px;
} */

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  #shorts-page .action-button-container {
    position: absolute;
    right: 4px;
    bottom: var(--h-short-content-box);
    margin-right: 6px;
    margin-left: 0px;
  }
  #shorts-page .action-button-container p {
    color: white;
    text-shadow: 1px 1px var(--clr-dark-400);
  }
}

/* xxs */
@media (max-width: 380px) {
  #shorts-page.mobile .short-card > .video-container {
    width: 100vw;
    height: 100svh;
    border-radius: 0;
  }
  #shorts-page.mobile {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
  }
}
