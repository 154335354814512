.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fd7888;
  /* Add additional styling based on your design requirements */
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.avatar-character {
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-size: 16px; Set the font size for the character */
  color: #fff; /* Set the text color for the character */
}
