@import '../../../theme/root-variables.css';

.short-card-image {
  border-radius: var(--radius-5);
  /* box-shadow: 0 3px 6px var(--shadow); */
  overflow: hidden;
  position: relative;
  /* border: 1px solid #707070; */
  border-radius: 10px;
}
.time {
  background: #f5234a none repeat scroll 0 0;
  border-radius: 2px;
  bottom: 5px;
  color: #fff;
  font-size: 11px;
  font-weight: 500;
  opacity: 0.7;
  padding: 0 6px;
  position: absolute;
  right: 5px;
}
.short-card:hover .time {
  opacity: unset;
}
.short-card-image img {
  width: 100%;
  aspect-ratio: 9/16;
  object-fit: cover;
}
.play-icon {
  opacity: 0;
  position: absolute;
}
.play-icon .fas,
.play-icon svg {
  bottom: 0;
  height: 40px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
}
.short-card:hover .play-icon {
  background: #000 none repeat scroll 0 0;
  border-radius: 2px;
  bottom: 0;
  color: #fff;
  font-size: 35px;
  left: 0;
  opacity: 0.4;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  transition-duration: 0.4s;
}
.short-title a {
  color: var(--t-clr-dark-400) !important;
  font-weight: 600;
  font-size: var(--fs-400);
  text-decoration: none;
}
.short-title {
  margin-bottom: 5px;
}

.short-view {
  color: var(--clr-dark-100);
  font-size: var(--fs-300);
  font-weight: 500;
  margin: 2px 0 0;
}
.short-card {
  /* background: var(--clr-light-400) none repeat scroll 0 0; */
  /* border-radius: 2px; */
  /* box-shadow: 0 3px 6px var(--shadow); */
  transition-duration: 0.4s;
}
.short-card-body {
  position: relative;
  padding: 12px;
}
