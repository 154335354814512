#save-modal {
  /* max-width: 300px; */
}

#processing-modal {
  /* top: 50%;
  transform: translateY(-50%); */
}
#processing-modal .metamask {
  position: absolute;
  top: -47px;
  transform: translateX(-50%);
}
#processing-modal .content {
  margin-top: calc(47px + 10px);
  margin-bottom: 10px;
}
.gray-box {
  /* background-color: var(--gray-box); */
  background-color: var(--clr-F6F6F6-252525);
}

#short-comments-modal {
  align-items: flex-start !important;
  max-width: 400px;
}

/* #short-comments-modal .infinite-scroll-component {
  height: 40vh !important;
  overflow: auto !important;
} */

/* otp modal css */
.otp-input {
  width: 50px !important;
  height: 50px !important;
  font-size: 30px;
  background-color: transparent;
  border: 1px solid var(--clr-border-dropdown);
  color: var(--clr-dark-300);
}

.otp-input-separator {
  width: 12px;
  height: 1px;
  background: var(--clr-border-dropdown);
}

.otp-box input[type='number']::-webkit-inner-spin-button,
.otp-box input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
