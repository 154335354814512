@import '../../theme/root-variables.css';

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 10;
}
.osahan-nav {
  /* min-height: 70px; */
  height: var(--h-navbar);
  /* box-shadow: 0 0 10px #f2f2f2 !important; */
  padding: 0 !important;
  background-color: var(--header) !important;
}

.osahan-nav a {
  color: var(--clr-dark-300) !important;
  /* color: #fff !important; */
  text-decoration: none !important;
}
.osahan-nav .left {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.osahan-nav .menubar-div {
  width: 32px;
  height: 32px;
  margin: auto;
  background: var(--clr-primary-400);
  background: linear-gradient(
    139deg,
    var(--clr-menubar-g1),
    var(--clr-menubar-g2)
  );
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  transition: all ease-in 0.4s;
}
.osahan-navbar-search .form-control {
  flex: 1;
  background: var(--clr-navbar-search) none repeat scroll 0 0 !important;
  /* background: var(--search-bar) none repeat scroll 0 0 !important;
  border: medium none;
  font-size: 14px !important;
  max-width: 607px !important;
  width: 607px !important;
   */
  font-size: 14px !important;
  /* max-width: 607px !important;
  width: 607px !important; */
  border-color: var(--clr-search-bar-border) !important;
  border-radius: 5px 0 0 5px !important;
  color: white !important;
}
.osahan-navbar-search {
  /* margin-right: 84px !important; */
  flex: 1;
  /* padding: 0 20px; */
}
.osahan-navbar-search .btn {
  background: var(--search-bar) none repeat scroll 0 0;
  border: medium none;
  font-size: 14px;
  padding: 0 18px;
}
.osahan-right-navbar .nav-link,
.osahan-right-navbar .nav-link {
  padding: 17px 13px;
  position: relative;
  color: var(--clr-dark-300);
  font-size: 16px;
  font-weight: 600;
}

.osahan-right-navbar .nav-link:hover,
.osahan-right-navbar .nav-link:hover {
  color: #272727;
}
.osahan-right-navbar {
  margin: 0 7px 0 0;
}
.navbar .dropdown-menu.show {
  right: 0;
  left: auto;
}
/* .user-dropdown-link img {
  border-radius: 50px;
  height: 30px;
  margin: -9px 2px -6px 0;
  width: 30px;
} */

.navbar-nav .form-inline .input-group {
  width: 100%;
}
.navbar-nav .nav-item.dropdown .dropdown-toggle::after {
  width: 1rem;
  text-align: center;
  float: right;
  vertical-align: 0;
  border: 0;
  font-weight: 900;
  content: '\f105';
  font-family: 'Font Awesome 5 Free';
}
.navbar-nav .nav-item.dropdown.show .dropdown-toggle::after {
  content: '\f107';
}
.navbar-nav .nav-item.dropdown.no-arrow .dropdown-toggle::after {
  display: none;
}
.navbar-nav .nav-item .nav-link:focus {
  outline: none;
}
.navbar-nav .nav-item .nav-link .badge {
  border: medium none !important;
  border-radius: 50% !important;
  font-size: 8px;
  height: 14px;
  width: 14px;
  position: absolute;
  text-align: center;
  top: 18px;
  right: 10px;
}
.modal-content {
  border: medium none;
  border-radius: 2px;
}

@media (max-width: 575.98px) {
  .osahan-right-navbar .nav-link,
  .osahan-right-navbar .nav-link {
    font-size: 0;
    padding: 22px 5px !important;
  }
  .osahan-right-navbar .nav-link,
  .osahan-right-navbar .nav-link .fas,
  .osahan-right-navbar .nav-link,
  .osahan-right-navbar .nav-link svg {
    font-size: 12px;
  }
  #sidebarToggle {
    margin: 0 4px 0 16px;
    width: auto !important;
  }
  .navbar-brand img {
    vertical-align: top;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .osahan-right-navbar .nav-link,
  .osahan-right-navbar .nav-link {
    font-size: 0;
    padding: 22px 5px !important;
  }
  .osahan-right-navbar .nav-link,
  .osahan-right-navbar .nav-link .fas,
  .osahan-right-navbar .nav-link,
  .osahan-right-navbar .nav-link svg {
    font-size: 12px;
  }
  #sidebarToggle {
    margin: 0 9px 0 0;
  }
  .navbar-brand img {
    vertical-align: top;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .osahan-right-navbar .nav-link,
  .osahan-right-navbar .nav-link {
    font-size: 0;
    /* padding: 22px 5px !important; */
    padding: 0px 5px !important;
  }
  .osahan-right-navbar .nav-link,
  .osahan-right-navbar .nav-link .fas,
  .osahan-right-navbar .nav-link,
  .osahan-right-navbar .nav-link svg {
    font-size: 12px;
  }
  #sidebarToggle {
    margin: 0 9px 0 0;
  }
  .navbar-brand img {
    vertical-align: top;
  }
  .osahan-navbar-search .form-control {
    width: auto !important;
  }
  /* .osahan-navbar-search {
    margin-right: 15px !important;
  } */
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .osahan-right-navbar .nav-link,
  .osahan-right-navbar .nav-link {
    font-size: 0;
    padding: 0px 5px !important;
    /* padding: 22px 5px !important; */
  }
  .osahan-right-navbar .nav-link,
  .osahan-right-navbar .nav-link .fas,
  .osahan-right-navbar .nav-link,
  .osahan-right-navbar .nav-link svg {
    font-size: 12px;
  }
  #sidebarToggle {
    margin: 0 9px 0 0;
  }
  .navbar-brand img {
    vertical-align: top;
  }
  .osahan-navbar-search .form-control {
    width: auto !important;
  }
  /* .osahan-navbar-search {
    margin-right: 15px !important;
  } */
}

@media (min-width: 768px) {
  .navbar-nav .form-inline .input-group {
    width: auto;
  }
}

.nav-btn-circle {
  /* background-color: var(--clr-section-heading-circle-icon); */
  background-color: #191919;
  cursor: pointer;
  transition: all ease-out 0.4s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  overflow: hidden;
  /* padding: 10px; */
  /* box-shadow: 0 0 2px #ccc; */
}

.nav-btn-circle > img {
  /* width: 16px; */
  /* height: 16px; */
}
