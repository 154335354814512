.video-close {
  color: #fff !important;
  font-size: 18px;
  position: absolute;
  right: 6px;
  z-index: 1;
  cursor: pointer;
}

.video-close:hover {
  opacity: 0.5;
}

.history-video .progress {
  border-radius: 2px;
  font-size: 9px;
  font-weight: 700;
  height: 13px;
  margin: 12px 14px -4px 13px;
}

.history-video {
  /* display: flex;
  flex-wrap: wrap; */
  padding: 10px !important;
  border-radius: 5px;
}

/* .history-video .video-card-image{
  height: 150px;
} */

.history-video .video-card-image img {
  aspect-ratio: 16/9;
  object-fit: cover;
}



@media screen and (max-width: 575px) {
  
button.custom-slick-btn.custom-slick-prev {
  display: block !important;
}
button.custom-slick-btn.custom-slick-next {
  display: block !important;
}
.top-category.section-padding .slick-slider {
  padding: 0rem  !important;
}
}
@media screen and (max-width: 575px) {
  
  .video-title {
    margin-top: 10px;
  }}

/* .customShorts-slick-next {
  position: absolute;
  top: 40%;
  right: 10px;
  display: block !important;
  transform: translateY(-50%);
  transition: all 0.45s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
}
.customShorts-slick-prev {
  position: absolute;
  top: 40%;
  left: 10px;
  transform: translateY(-50%);
  display: block !important;
  transition: all 0.45s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
} */