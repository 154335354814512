.btn-circle {
  border-radius: 50%;
  background-color: white;
  /* width: 40px;
  height: 40px; */
  aspect-ratio: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 8px var(--clr-dark-200);
  outline: none;
  border: 0;
}

.btn-circle:hover {
  /* opacity: 0.6; */
  background-color: var(--clr-light-500);
}

.btn-circle:focus {
  outline: none !important;
}

/* bootstrap icon btn */
/**
 * Relevant CSS
 * ------------
 * This works best if you are building your own bootstrap from src and can use their functions to build classes for each shaded button color variant with specific background-colors for each type.
 * You then also wouldn't need to include the extraneous .outline/.primary/.info etc. classes since you could use the bootstrap build functions instead to include your overrides.
 * This codepen, however, is done without doing that in case you would rather use the pre-compiled version of bootstrap
 */
/* button.btn.btn-icon {
  display: flex;
}
button.btn.btn-icon > i:first-child:not(:only-child) {
  margin-right: 0.75rem;
}
button.btn.btn-icon > span + i {
  margin-left: 0.75rem;
}
button.btn.shaded.btn-icon {
  display: flex;
  position: relative;
}
button.btn.shaded.btn-icon.shaded > i {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 100;
  top: 0;
  border-radius: 0;
  padding: 0 0.4rem;
  height: 100%;
}
button.btn.shaded.btn-icon.shaded > i::before {
  background-color: rgba(6, 7, 8, 0.3);
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -10;
}
button.btn.shaded.btn-icon.left.shaded {
  padding-left: 2.7rem;
}
button.btn.shaded.btn-icon.left.shaded > i {
  left: 0;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  margin-right: 0.75rem;
}
button.btn.shaded.btn-icon.right.shaded {
  padding-right: 2.7rem;
}
button.btn.shaded.btn-icon.right.shaded > i {
  right: 0;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  margin-left: 0.75rem;
}
button.btn.shaded.btn-icon.primary > i::before {
  background-color: rgba(0, 86, 179, 0.5);
}
button.btn.shaded.btn-icon.danger > i::before {
  background-color: rgba(167, 29, 42, 0.5);
}
button.btn.shaded.btn-icon.info > i::before {
  background-color: rgba(15, 102, 116, 0.5);
}
button.btn.shaded.btn-icon.outline.primary > i::before {
  background-color: rgba(77, 163, 255, 0.3);
}
button.btn.shaded.btn-icon.outline.danger > i::before {
  background-color: rgba(231, 118, 129, 0.3);
}
button.btn.shaded.btn-icon.outline.info > i::before {
  background-color: rgba(54, 206, 230, 0.3);
} */
