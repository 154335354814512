.customShorts-slick-next {
    position: absolute;
    top: 40%;
    opacity: 0.5;
    right: -12px;
    display: block;
    transform: translateY(-50%);
    transition: all 0.45s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  }
  .customShorts-slick-prev {
    position: absolute;
    opacity: 0.5;
    top: 40%;
    left: -13px;
    transform: translateY(-50%);
    display: block;
    transition: all 0.45s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  }


  .customShorts-slick-prev:hover {
  
    opacity:1;
   
  }
  .customShorts-slick-next:hover {
  
    opacity:1;
   
  }