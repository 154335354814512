.video-page-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto;
}

.video-page-grid .item-player {
  grid-area: 1 / 1 / 2 / 9;
  /* border-radius: 12px; */
  overflow: hidden;
}
.video-page-grid .item-details {
  grid-area: 2 / 1 / 3 / 9;
}

.video-page-grid .item-recommendations {
  grid-area: 1 / 9 / 13 / 13;
  width: 375px;
  margin-left: 16px;
}

.video-page-grid .item-comments {
  grid-area: 3 / 1 / 4 / 9;
}

@media (min-width: 1100px) {
  .video-page-grid.toggle .item-player {
    grid-area: 1 / 1 / 2 / 13;
  }

  .video-page-grid.toggle .item-recommendations {
    grid-area: 2 / 9 / 5 / 13;
    margin-top: 1rem;
  }
}

@media (max-width: 1100px) {
  .video-page-grid .item-player {
    grid-area: 1 / 1 / 2 / 13;
    border-radius: 0px;
  }
  .video-page-grid .item-details {
    grid-area: 2 / 1 / 3 / 13;
  }
  .video-page-grid .item-recommendations {
    /* grid-area: 3 / 1 / 4 / 13; */
    grid-area: 4 / 1 / 5 / 13;
    width: 100%;
    margin-top: 16px;
    margin-left: 0px;
  }
  .video-page-grid .item-comments {
    /* grid-area: 4 / 1 / 5 / 13; */
    grid-area: 3 / 1 / 4 / 13;
  }
  .shaka-theater-mode-container {
    display: none !important;
  }
}

.adblock {
  background: #eceff0 none repeat scroll 0 0;
  border-radius: 2px;
  margin: 2rem 0;
  padding: 27px 10px;
  text-align: center;
  width: 100%;
}
.video-card-list .video-card-image {
  float: left;
  /* height: 77px; */
  margin: 0 12px 0 0;
  /* width: 122px; */
  width: 140px;
  aspect-ratio: 16/9;
}
.video-card-list .video-card-body {
  padding: 0;
}
.video-card-list {
  background: transparent none repeat scroll 0 0;
  box-shadow: none;
  display: flex;
  margin-bottom: 0.5rem;
  width: 100%;
}
/* .video-card-list .video-card-body .video-title a {
	font-size: 13px;
} */
.video-card-list .video-card-body .video-title {
  line-height: 17px;
}
.video-card-list .video-card-body .right-action {
  opacity: 0;
}
.video-card-list:hover .video-card-body .right-action {
  opacity: unset;
}

/* Comment Box */
.single-video-comment-tabs .nav.nav-tabs {
  border-bottom: 2px solid #e7e9ee;
  margin: -15px -15px 15px;
  padding: 0 15px;
}
.single-video-comment-tabs .nav.nav-tabs .nav-link {
  border-color: #b7b9be !important;
  border-radius: 2px !important;
  border-style: none none solid;
  border-width: 0 0 2px;
  color: #b7b9be !important;
  font-weight: 600;
  margin: 0 !important;
  padding: 16px 17px !important;
  text-transform: uppercase;
}
.single-video-comment-tabs .nav.nav-tabs .nav-link.active,
.single-video-comment-tabs .nav.nav-tabs .nav-link:hover {
  border-color: #000 !important;
  color: #000 !important;
}
.single-video-comment-tabs .nav-tabs .nav-item {
  margin-bottom: -2px;
}

/* Comment Media, Custom Media */
.reviews-members {
  border-bottom: 1px solid var(--clr-table-border);
  margin: 0 -15px;
  padding: 15px;
}

.reviews-members .avatar-div {
  background-color: var(--clr-section-heading-circle-icon);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 0.5rem;
  overflow: hidden;
}

.reviews-members-header .text-black {
  color: #000 !important;
  font-weight: 600;
}
.reviews-members-header .text-gray {
  color: var(--clr-dark-100) !important;
  font-size: 12px;
  font-weight: unset;
  margin: 0px 4px;
}
.reviews-members-header {
  /* margin-bottom: 11px; */
}

.reviews-members-body {
  display: flex;
  align-items: center;
}
.reviews-members-body p {
  color: #7b7b7b;
  font-size: var(--fs-300);
  margin-bottom: 0.5rem;
}
.reviews-members-body .mention {
  color: var(--clr-link);
  margin-right: 5px;
  font-size: var(--fs-300);
  margin-bottom: 0.5rem;
}
.reviews-members-footer {
  display: flex;
  align-items: center;
  width: 100%;
}
.reviews-members .media img {
  /* border-radius: 50px;
  height: 51px;
  width: 51px; */
}
.reviews-members:last-child {
  border: medium none;
  padding-bottom: 5px;
}

.comment-input-box {
  background-color: var(--gray-box);
  border-radius: 9px;
  cursor: text;
}
.comment-input-box textarea {
  border: none !important;
  background-color: transparent !important;
  resize: none;
}

/* UsersWhoLiked */
.total-like-user-main img {
  border: 2px solid #fff;
  box-shadow: 0 0 2px #ccc;
  height: 25px !important;
  margin: 0 0 0 -11px;
  width: 25px !important;
}

.total-like {
  border: 1px solid #ff516b;
  border-radius: 3px;
  color: #ff516b !important;
  font-size: 11px;
  padding: 4px 10px;
  vertical-align: sub;
}
.single-video-left .total-like:hover {
  background: #ff516b none repeat scroll 0 0;
  border: 1px solid #ff516b;
  border-radius: 3px;
  color: #ffffff !important;
  font-size: 11px;
  padding: 4px 10px;
  vertical-align: sub;
}
.total-like-user-main.ml-2 {
  margin: 0 9px 0 17px !important;
}

/**/
.video-slider-right-list {
  /* background: #fff none repeat scroll 0 0;
  border-radius: 2px;
  box-shadow: 0 0 11px #ececec; */
  height: 314px;
  overflow: auto;
}
.video-slider-right-list .video-card-list .video-card-image {
  height: 59px;
  width: 95px;
}
.video-slider-right-list .video-card-image img {
  object-fit: scale-down;
}
.video-slider-right-list .video-title a {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.video-slider-right-list .video-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.video-slider-right-list .video-card-list {
  cursor: pointer;
  margin-bottom: 1px;
  padding: 9px;
}
.video-slider-right-list .video-card-list:hover,
.video-slider-right-list .video-card-list.active {
  background: #eceff0 none repeat scroll 0 0;
}
.video-slider-right-list::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}
.video-slider-right-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

/**/

.box {
  padding: 15px;
  background: var(--clr-light-400) none repeat scroll 0 0;
  border-radius: var(--radius-5);
  /* box-shadow: 0 0 11px #ececec; */
  box-shadow: 0 3px 6px var(--shadow);
  transition-duration: 0.4s;
}
/* .single-video-title h2 {
	font-size: 18px;
} */
.single-video-title h2 a {
  color: #333 !important;
}

.single-video-author {
  gap: 16px;
}

.single-video-author > .author-box-right::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}

.single-video-author img {
  border-radius: 50px;
  height: 38px;
  margin: 0 13px 0 0;
  width: 38px;
}

.single-video-info-content h6 {
  font-size: 14px;
  font-weight: 600;
}

.single-video-info-content:hover {
  opacity: 0.8;
}

/* xs (extra small) */
@media (max-width: 575.98px) {
  .video-card-image img {
    width: 100%;
  }
  .video-page-grid .item-player {
    position: sticky;
    top: var(--h-navbar);
    z-index: 1;
  }
}

/* md (medium) */
@media (min-width: 768px) {
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .video-card-image img {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .video-card-image img {
    width: 100%;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .video-card-image img {
    width: 100%;
  }
}

.comment-body {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
}
