@import '../../../theme/root-variables.css';

.video-card-image {
  border-radius: 2px;
  box-shadow: 0 3px 6px var(--shadow);
  overflow: hidden;
  position: relative;
  padding: 0;
  height: fit-content;
}
.time {
  background: #f5234a none repeat scroll 0 0;
  border-radius: 2px;
  bottom: 5px;
  color: #fff;
  font-size: 11px;
  font-weight: 500;
  opacity: 0.7;
  padding: 0 6px;
  position: absolute;
  right: 5px;
}
.video-card:hover .time {
  opacity: unset;
}
.video-card-image img {
  width: 100%;
  object-fit: cover;
}
.play-icon {
  opacity: 0;
  position: absolute;
}
.play-icon .fas,
.play-icon svg {
  bottom: 0;
  height: 40px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
}
.video-card-image:hover .play-icon {
  background: #000 none repeat scroll 0 0;
  border-radius: 2px;
  bottom: 0;
  color: #fff;
  font-size: 35px;
  left: 0;
  opacity: 0.4;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  transition-duration: 0.4s;
}
.video-title > a {
  color: var(--t-clr-dark-400) !important;
  font-weight: 600;
  font-size: var(--fs-400);
  text-decoration: none;
  cursor: pointer;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: anywhere;
}
.video-title {
  display: block;
  margin-bottom: 5px;
  overflow: hidden;
}
.video-page {
  font-size: var(--fs-300);
  color: var(--t-clr-dark-400) !important;
  font-weight: 500;
}
.video-view {
  color: var(--clr-dark-100);
  font-size: var(--fs-300);
  font-weight: 400;
  /* margin: 2px 0 0; */
}

.small-video-view {
  color: var(--clr-dark-100);
  font-size: var(--fs-200);
  font-weight: 400;
  margin: 2px 0 0;
}
.video-card {
  background: var(--clr-light-400) none repeat scroll 0 0;
  border-radius: 2px;
  box-shadow: 0 3px 6px var(--shadow);
  /* transition-duration: 0.4s; */
  flex: 1;
  /* overflow: hidden; */
}

/*  */
.video-card-body {
  padding: 12px;
}

@media (max-width: 575.98px) {
  .hover-trigger-container .trigger-element {
    visibility: visible !important;
  }
}
