.custom-tabs > .tab-item {
  font-size: var(--fs-400);
  font-weight: 500;
  color: var(--clr-dark-300);
}

/* - Branding */
#branding .preview {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* background-color: var(--clr-light-300); */
  width: 280px;
  /* aspect-ratio: 16/9; */
  min-height: 160px;
}

#branding .preview > .profile-preview-img {
  border-radius: 50%;
  width: 140px;
  height: 140px;
}

#branding .preview > .banner-preview-img {
  max-width: 85%;
  max-height: 85%;
}

#branding .preview > .watermark-preview-img {
  max-width: 40px;
  max-height: 40px;
  aspect-ratio: 1;
  position: absolute;
  right: 8px;
  bottom: 35px;
}

#branding p {
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  font-size: 13px;
  line-height: 20px;
}

@media (max-width: 575.98px) {
  #branding .preview {
    /* width: 240px; */
    /* height: 160px; */
    margin: auto;
    width: 100%;
  }
  #branding .banner-preview-container,
  #branding .avatar-preview-container {
    width: 100%;
  }
}
