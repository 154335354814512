@import '../../theme/root-variables.css';

.sidebar::-webkit-scrollbar {
  width: 2px;
  background-color: transparent;
}
.sidebar::-webkit-scrollbar-thumb {
  /* border-radius: 10px;  */
  /* -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3); */
  background-color: #cdcdcd;
}

.channel-sidebar-list {
  /* border-top: 1px solid #ff516b; */
  margin: 6px 0 0;
  padding: 7px 14px;
}
.channel-sidebar-list h6 {
  color: #fff !important;
  font-size: 14px;
  font-weight: 200;
  margin-bottom: 15px;
  /* margin-top: 9px; */
}
.channel-sidebar-list ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
  /* box-shadow: 0px 0px 6px -2px grey; */
}
.channel-sidebar-list ul li img {
  border-radius: 50px;
  height: 28px;
  margin: 0 14px 0 0;
  width: 28px;
}
.channel-sidebar-list li a {
  color: #fff;
  display: inline-block;
  overflow: hidden;
  padding: 6px 0;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.channel-sidebar-list li a .badge {
  float: right;
  margin: 7px 0 0;
  position: absolute;
  right: 20px;
}
.sidebar.toggled .channel-sidebar-list h6 {
  font-size: 11px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.sidebar.toggled .channel-sidebar-list {
  padding: 7px;
  text-align: center;
}
.sidebar.toggled .channel-sidebar-list li img {
  display: list-item;
  margin: auto auto 4px;
}
.sidebar.toggled .channel-sidebar-list li a {
  font-size: 10px;
  font-weight: 500;
}
.sidebar.toggled .channel-sidebar-list li .badge {
  display: none;
}

.sidebar {
  transition: all 0.4s ease;
  overflow: hidden;
  width: 90px !important;
  background: var(--clr-sidebar-g1);
  /* Old browsers */
  background: -moz-linear-gradient(
    -45deg,
    var(--clr-sidebar-g1),
    var(--clr-sidebar-g2)
  );
  /* FF3.6-15 */
  background: -webkit-linear-gradient(
    -45deg,
    var(--clr-sidebar-g1),
    var(--clr-sidebar-g2)
  );
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    135deg,
    var(--clr-sidebar-g1),
    var(--clr-sidebar-g2)
  );
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=var(--clr-sidebar-g1), endColorstr=var(--clr-sidebar-g2),GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */
  /* min-height: 100%; */
  z-index: 9;
  padding: 2px 0;
  position: fixed;
  height: 100%;
  max-height: calc(100vh - 52px) !important;
}
.sidebar:hover {
  overflow-y: auto;
}
.sidebar .nav-item {
  border-bottom: 0.1px solid var(--clr-sidebar-g1);
}

/* .sidebar .nav-item:last-child {
  margin-bottom: 1rem;
} */
.sidebar .nav-item .nav-link {
  font-size: 14px;
  padding: 12px 14px !important;
  /* padding: 1rem 0 1rem 25px; */
  /* padding: .75rem 1rem; */
  text-align: center;
  width: 90px;
  font-weight: 100;
  color: #ffffff;
}

.sidebar .nav-item .nav-link > img {
  width: 18px;
}

.sidebar .nav-item .nav-link > svg {
  width: 18px;
  fill: #f7f7f7;
  height: 100%;
  max-height: 18px;
}

.sidebar .nav-item .nav-link:hover,
.sidebar .active {
  /* background-color: rgba(255, 255, 255, 0.2) !important; */

  background-color: var(--clr-sidebar-item-hover-g1);
  /* background: linear-gradient(to right, var(-sidebar-hover1), var(--sidebar-hover2))  !important; */
}

.sidebar .nav-item .nav-link.active > svg {
  fill: var(--clr-sidebar-item-svg-hover);
}

.sidebar .nav-item .nav-link .fas {
  color: #ffffff;
}
.sidebar .nav-item .nav-link span {
  display: inline;
  font-size: var(--fs-300);
  margin: 0 0 0 18px;
  color: white;
}
.sidebar .nav-item .dropdown-menu {
  position: absolute !important;
  -webkit-transform: none !important;
  transform: none !important;
  left: calc(90px + 0rem) !important;
  margin: 0;
}
.sidebar .dropdown-menu.show {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.sidebar .nav-item .dropdown-menu.dropup {
  bottom: 0;
  top: auto !important;
}
.sidebar .nav-item.dropdown .dropdown-toggle::after {
  display: none;
}
.sidebar .nav-item .nav-link:active,
.sidebar .nav-item .nav-link:focus,
.sidebar .nav-item .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}

.sidebar.toggled {
  transform: translateX(-100%);
  overflow: hidden;
}

.sidebar .profile-root .profile-image-circle {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
  /* background: #fd7888; */
  background: var(--clr-studio-sidebar-avatar);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px auto;
}
.sidebar.toggled .profile-root .profile-image-circle {
  width: 50px;
  height: 50px;
  overflow: hidden;
  margin: 16px auto;
}
.sidebar .profile-root .profile-image-circle h2 {
  color: white;
  font-size: 26px;
  font-weight: 600;
}
.sidebar.toggled .profile-root .profile-image-circle h2 {
  /* font-size: 16px; */
  font-size: 20px;
}
.sidebar .profile-root h4,
.sidebar.toggled .profile-root h4 {
  display: none !important;
}
.sidebar .profile-root p,
.sidebar.toggled .profile-root p {
  display: none !important;
}

@media (max-width: 575.98px) {
  .sidebar {
    width: 100% !important;
  }
  .sidebar .nav-item .nav-link {
    /* padding: 8px 14px !important; */
    display: flex;
    gap: 10px;
    width: 100%;
    align-items: center;
  }
  .sidebar .nav-item .nav-link span {
    margin: 0 !important;
  }
  .sidebar .channel-sidebar-list h6 {
    font-size: 11px !important;
  }
  .sidebar .nav-item .nav-link span {
    display: block;
    font-size: 0.65rem;
  }
  .sidebar .channel-sidebar-list h6 {
    font-size: 11px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .sidebar .channel-sidebar-list {
    padding: 7px;
    text-align: center;
  }
  .sidebar .channel-sidebar-list li img {
    display: list-item;
    margin: auto auto 4px;
  }
  .sidebar .channel-sidebar-list li a {
    font-size: 10px;
    font-weight: 500;
  }
  .sidebar .channel-sidebar-list li .badge {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .sidebar .nav-item .nav-link {
    /* padding: 8px 14px !important; */
  }

  .sidebar .nav-item .nav-link span {
    margin: 0 !important;
  }
  .sidebar .channel-sidebar-list h6 {
    font-size: 11px !important;
  }
  .sidebar .nav-item .nav-link span {
    display: block;
    font-size: 0.65rem;
  }
  .sidebar .channel-sidebar-list h6 {
    font-size: 11px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .sidebar .channel-sidebar-list {
    padding: 7px;
    text-align: center;
  }
  .sidebar .channel-sidebar-list li img {
    display: list-item;
    margin: auto auto 4px;
  }
  .sidebar .channel-sidebar-list li a {
    font-size: 10px;
    font-weight: 500;
  }
  .sidebar .channel-sidebar-list li .badge {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .sidebar .nav-item .nav-link {
    /* padding: 8px 14px !important; */
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .sidebar .nav-item .nav-link {
    /* padding: 8px 14px !important; */
  }
}

/*///////////////////////////////////////////////////*/
@media (min-width: 768px) {
  .sidebar {
    height: 100%;
    position: fixed;
    width: 225px !important;
  }

  .sidebar .profile-root .profile-image-circle {
    width: 112px;
    height: 112px;
  }

  .sidebar .profile-root .profile-image-circle h2 {
    font-size: 45px;
  }

  .sidebar .profile-root h4 {
    display: block !important;
    font-size: var(--fs-400);
    color: white;
  }
  .sidebar .profile-root p {
    display: block !important;
    font-size: var(--fs-300);
    color: white;
  }
  .sidebar .nav-item .nav-link {
    display: block;
    width: 100%;
    text-align: left;
    /* padding: 1rem; */
    width: 225px;
  }
  .sidebar .nav-item .nav-link span {
    display: inline;
    font-size: var(--fs-300);
    margin: 0 0 0 18px;
  }
  .sidebar .nav-item .dropdown-menu {
    position: static !important;
    margin: 0 1rem;
    top: 0;
  }
  .sidebar .nav-item.dropdown .dropdown-toggle::after {
    display: block;
  }
  .sidebar.toggled {
    overflow: visible;
    width: 90px !important;
    transform: translateX(0);
  }
  .sidebar.toggled:hover {
    overflow-x: auto;
  }
  .sidebar.toggled .nav-item:last-child {
    margin-bottom: 1rem;
  }
  .sidebar.toggled .nav-item .nav-link {
    text-align: center;
    padding: 0.75rem 1rem;
    width: 90px;
  }
  .sidebar.toggled .nav-item .nav-link span {
    display: block;
    margin: 0;
    font-size: var(--fs-100);
    font-weight: 100;
    margin-top: 2px;
    color: white;
  }
  .sidebar.toggled .nav-item .dropdown-menu {
    position: absolute !important;
    -webkit-transform: none !important;
    transform: none !important;
    left: calc(90px + 0.5rem) !important;
    margin: 0;
  }
  .sidebar.toggled .nav-item .dropdown-menu.dropup {
    bottom: 0;
    top: auto !important;
  }
  .sidebar.toggled .nav-item.dropdown .dropdown-toggle::after {
    display: none;
  }
}

.sidebar.fixed-top {
  top: 56px;
  height: calc(100vh - 56px);
  overflow-y: auto;
}
